var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"centered":"","flat":"","hide-slider":"","color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.id,attrs:{"ripple":false}},[_vm._v(_vm._s(item.name))])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabContent),function(item){return _c('v-tab-item',{key:item.id,attrs:{"transition":"fade-transition"}},[(item.beritabencana)?_c('div',{staticClass:"slick-space-gutter--15 slickdot--20"},[(_vm.beritabencana.length)?_c('VueSlickCarousel',_vm._b({staticClass:"rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.beritabencana),function(brt,i){return _c('div',{key:i,staticClass:"im_box mt--30"},[_c('div',{staticClass:"thumbnail"},[_c('router-link',{attrs:{"to":{ name: 'Berita Details', params: { id: brt.id } }}},[_c('img',{staticClass:"w-100",attrs:{"src":brt.foto,"alt":"Blog Images"}})])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content_heading"},[_c('div',{staticClass:"category_list"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_vm._v(_vm._s(brt.jenis))])],1),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: brt.id },
                        }}},[_vm._v(_vm._s(brt.judul))])],1)]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn btn-opacity",attrs:{"to":{ name: 'Berita Details', params: { id: brt.id } }}},[_vm._v(" Baca Selengkapnya ")])],1)]),_c('router-link',{staticClass:"transparent_link",attrs:{"to":{ name: 'Berita Details', params: { id: brt.id } }}})],1)])}),0):_vm._e()],1):_vm._e(),(item.beritabpbd)?_c('div',{staticClass:"slick-space-gutter--15 slickdot--20"},[_c('VueSlickCarousel',_vm._b({staticClass:"rn-slick-activation slick-dotted rn-slick-dot mt--10"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.beritabpbd),function(bpbd,i){return _c('div',{key:i,staticClass:"im_box mt--30"},[_c('div',{staticClass:"thumbnail"},[_c('router-link',{attrs:{"to":"/blog-details"}},[_c('img',{staticClass:"w-100",attrs:{"src":bpbd.foto,"alt":"Blog Images"}})])],1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content_heading"},[_c('div',{staticClass:"category_list"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_vm._v(_vm._s(bpbd.jenis))])],1),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
                          name: 'Berita Details',
                          params: { id: bpbd.id },
                        }}},[_vm._v(_vm._s(bpbd.judul))])],1)]),_c('div',{staticClass:"content_footer"},[_c('router-link',{staticClass:"rn-btn btn-opacity",attrs:{"to":{
                        name: 'Berita Details',
                        params: { id: bpbd.id },
                      }}},[_vm._v(" Baca Selengkapnya ")])],1)]),_c('router-link',{staticClass:"transparent_link",attrs:{"to":"/blog-details"}})],1)])}),0)],1):_vm._e()])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }