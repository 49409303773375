var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mx-3"},_vm._l((_vm.dataKerusakan),function(item,i){return _c('v-col',{key:i,staticClass:"portfolio-tilthover",attrs:{"xl":"3","lg":"3","md":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"im_portfolio"},[_c('div',{staticClass:"thumbnail_inner"},[_c('div',{staticClass:"thumbnail"},[_c('a',{attrs:{"to":{
                name: 'DataKerusakanDetails',
                params: { id: item.id, slug: item.slug },
              }}},[_c('img',{staticClass:"w-100",attrs:{"src":item.foto,"alt":"Portfolio Images"}})])])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"portfolio_heading mb-6"},[_c('div',{staticClass:"category_list"},[_c('router-link',{attrs:{"to":{
                    name: 'DataKerusakanDetails',
                    params: { id: item.id, slug: item.slug },
                  }}},[_vm._v(_vm._s(item.kecamatan))])],1),_c('h4',{staticClass:"heading-title"},[_c('router-link',{attrs:{"to":{
                    name: 'DataKerusakanDetails',
                    params: { id: item.id, slug: item.slug },
                  }}},[_vm._v(" Kecamatan "+_vm._s(item.kecamatan)+", Kelurahan "+_vm._s(item.kelurahan))])],1)]),_c('div',{staticClass:"portfolio_hover"},[_c('p',[_vm._v(_vm._s(item.bencana))])])])]),_c('router-link',{staticClass:"transparent_link",attrs:{"to":{
            name: 'DataKerusakanDetails',
            params: { id: item.id, slug: item.slug },
          }}})],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }